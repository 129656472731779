import React from "react";
import { isSSR } from "utility/helper";

const ParseQrcode = ({ location }) => {
  const { hash } = location;
  const [, amount, password] = hash.split("#");

  if (isSSR) {
    window.location.assign(
      `tel:*610*1*0697707418*${amount < 50 ? 50 : amount}*${password}%23`
    );
  }

  return <div>ParseQrcode</div>;
};

export default ParseQrcode;
